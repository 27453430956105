import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import data from '../../sections/occasion/data';
import Footer from '../../sections/common/FooterWithNav';
import futureCelebrating from '../../images/occasion/graduation.png';
import futurePhone from '../../images/occasion/iPhone12.png';
import HeaderSection from '../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionGraduation: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Celebrate someone’s graduation with a group gift or ecard from illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Group Gifts for the Graduate'}
          paragraphs={[
            'Your cousin’s graduation is coming up. It hasn’t been an easy journey for him and your family really wants to celebrate this rite of passage. The problem is you all can’t be together. So, you organize a group gift with illume.',
            'You pick out a unique gift from illume’s digital marketplace, contribute with personal messages, photos, and more – and then hit send when they’re ready.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=9cd34313-699f-40af-aa67-ca59a69f07d0&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Graduation',
            },
            phone: {
              image: futurePhone,
              label: 'Graduation Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of celebrating milestones'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionGraduation;
